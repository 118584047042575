import React, { Component } from "react";
import videoBG from './sea_of_clouds.mp4';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    const myStyle = {
        backgroundImage: "url(/clouds.jpg)",
        height: "100vh",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

    };
  
    return (
      

      <div>
          <div className="content">
            <div className="comingSoon">Coming Soon</div>
            <div className="title">World Travel eSIM</div>
            <div className="bottom">© 2023 ObjectNova, Inc.</div>
            
          </div>
          
          <video className='videoTag' autoPlay loop muted>
            <source src={videoBG} type='video/mp4' />
          </video>
          
      </div>
    );
  }
}

export default App;
